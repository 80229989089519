<template>
  <Loading v-if="isLoading"></Loading>
  <main v-else>
    <div>
      <h1 class="font-bold text-lg mt-4">Airtime Cashback Settings</h1>
      <button
        class="bg-gray-800 py-3 px-6 rounded-md text-white"
        @click="
          $router.push({
            name: 'Wallet',
          })
        "
      >
        <svg
          class="w-5 h-5 fill-current text-blue-500"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
          />
        </svg>
      </button>
      <div class="grid grid-cols-4 gap-5 mt-20">
        <div
          class="border h-72 rounded-md shadow-md border-2 flex justify-center items-center p-20"
        >
          <p v-if="!newFee" class="cursor-pointer" @click="newFee = true">
            Add new
          </p>
          <div v-if="newFee">
            <input
              class="mt-8 border w-full ring-2 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
              v-model="name"
              placeholder="Enter Airtime Name"
            />

            <input
              type="number"
              min="0"
              onkeyup="if(this.value<0)this.value=1"
              onblur="if(this.value<0)this.value=1"
              class="border w-full ring-2 ring-blue-700 mt-5 rounded-md py-4 pl-3 border-gray-300 border-2"
              v-model="value"
              placeholder="Enter value"
            />
            <div class="flex items-center mt-4">
              <p
                class="mt-4 text-gray-600 font-bold cursor-pointer"
                @click="newFee = false"
              >
                Back
              </p>
              <p
                @click="createNewAirtime"
                class="mt-4 text-blue-600 ml-5 font-bold cursor-pointer"
              >
                Create New Airtime
              </p>
            </div>
          </div>
        </div>
        <div
          :class="{ 'cursor-not-allowed pointer-events-none': newFee }"
          class="border h-72 rounded-md shadow-md border-2 flex justify-center items-center flex-col p-10"
          v-for="airtime in airtimeData"
          :key="airtime._id"
        >
          <p>
            {{ airtime.name }}
          </p>

          <p v-if="selected.name !== airtime.name" class="mt-4">
            {{ airtime.value }}%
          </p>

          <input
            v-else
            type="number"
            min="0"
            onkeyup="if(this.value<0)this.value=1"
            onblur="if(this.value<0)this.value=1"
            class="border w-full ring-2 ring-blue-700 mt-5 rounded-md py-4 pl-3 border-gray-300 border-2"
            v-model="value"
          />

          <p
            v-if="selected.name !== airtime.name"
            @click="update(airtime)"
            class="mt-4 text-blue-600 font-bold cursor-pointer"
          >
            Update
          </p>
          <div class="flex items-center" v-else>
            <p
              class="mt-4 text-gray-600 font-bold cursor-pointer"
              @click="
                selected = {};
                value = null;
                name = null;
              "
            >
              Back
            </p>
            <p
              @click="updateAirtimeFee"
              class="mt-4 text-blue-600 ml-5 font-bold cursor-pointer"
            >
              Change
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapActions } from "vuex";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      airtimeData: [],
      selected: {},
      value: null,
      newFee: false,
      name: null,
      isLoading: false,
    };
  },
  components: {
    Loading,
  },
  methods: {
    ...mapActions([
      "GET_ALL_AIRTIME",
      "CREATE_AIRTIME_RATE",
      "UPDATE_AIRTIME_RATE",
    ]),
    async makeRequest() {
      let res = await this.$store.dispatch("GET_ALL_AIRTIME");
      this.airtimeData = res.data;
    },

    async createNewAirtime() {
      let payload = {
        name: this.name,
        value: this.value,
        unit: "%",
        description:
          "Rate(Amount) given back to the merchant for successful recharge",
        scope: "ALL",
        type: "CASHBACK",
      };
      let res = await this.$store.dispatch("CREATE_AIRTIME_RATE", payload);
      if (res.status) {
        await this.makeRequest();
        this.newFee = false;
        this.value = null;
        this.name = null;
        alert("Airtime Updated Succesfully");
      } else {
        alert(res.data || "There was a problem");
      }
    },

    async update(airtime) {
      this.selected = airtime;
      this.value = airtime.value;
      this.name = airtime.name;
    },
    async updateAirtimeFee() {
      let payload = {
        id: this.selected._id,
        name: this.name,
        value: this.value,
        scope: "ALL",
        unit: "%",
        description:
          "Rate(Amount) given back to the merchant for successful recharge",
        type: "CASHBACK",
        status: "enabled",
      };
      let res = await this.$store.dispatch("UPDATE_AIRTIME_RATE", payload);
      if (res.status) {
        await this.makeRequest();
        this.selected = {};
        this.value = null;
        this.name = null;
        alert("Fee Changed Succesfully");
      } else {
        alert(res.data || "There was a problem");
      }
    },
  },
  async created() {
    this.isLoading = true;
    await this.makeRequest();
    this.isLoading = false;
  },
};
</script>

<style></style>
